import React from "react";
import { Row, Col } from "reactstrap";
import { FormikProps, Field } from "formik";
import {
  HeaderTitle,
  HeaderSectionTitle,
} from "../../../../components/Headers";
import {
  InputRow,
  InputDecimal,
  InputRadio,
  InputCheckbox,
} from "../../../../components/Inputs";
import {
  calculateFck,
  formatCPCode,
  formatSeriesCode,
  formatAgeLabel,
  calculateFckf,
} from "../utils";
import { formatSimpleDate } from "../../../../utils/format";
import { RUPTURE_FORM, DEFECTS } from "./constants";
import CPItem from "../components/CPItem";

interface Props extends FormikProps<TConcreteTestingBody> {
  series: TConcreteSeries;
  control: TConcreteControl;
  showWork: boolean;
}

const Form: React.FC<Props> = ({
  values,
  series,
  control,
  setFieldValue,
  showWork,
}) => {
  const renderCP = () => (
    <Row>
      <Col sm={6}>
        <dl className="row">
          {showWork && (
            <>
              <dt className="col-sm-6">Obra</dt>
              <dd className="col-sm-6">{values.work}</dd>
            </>
          )}
          <dt className="col-sm-6">
            <h3 className="h4-5 mt-1 mb-1">Série</h3>
          </dt>
          <dd className="col-sm-6">
            <h3 className="h4-5 mt-1 mb-1">
              {formatSeriesCode(series?.number || 0)}
            </h3>
          </dd>

          <dt className="col-sm-6">
            <h3 className="h4-5 mt-1 mb-1">Código</h3>
          </dt>
          <dd className="col-sm-6">
            <h3 className="h4-5 mt-1 mb-1">{formatCPCode(values.code || 0)}</h3>
          </dd>

          <dt className="col-sm-6">Data de moldagem</dt>
          <dd className="col-sm-6">
            {formatSimpleDate(series?.molding_date || "")}
          </dd>
        </dl>

        <InputRow label="Altura (mm)" label_col={6}>
          <Field
            name="height"
            component={InputDecimal}
            disabled={!control.dimensions_control}
            onChange={(value: number) => {
              setFieldValue("height", value);
            }}
            autoFocus={control.dimensions_control}
          />
        </InputRow>
        {!control.is_prismatic && (
          <InputRow label="Diametro (mm)" label_col={6}>
            <Field
              name="diameter"
              component={InputDecimal}
              disabled={!control.dimensions_control}
              onChange={(value: number) => {
                setFieldValue("diameter", value);
              }}
            />
          </InputRow>
        )}
        {control.is_prismatic && (
          <>
            <InputRow label="Distan. entre apoios (mm)" label_col={6}>
              <Field
                name="support_width"
                component={InputDecimal}
                disabled={!control.dimensions_control}
                onChange={(value: number) => {
                  setFieldValue("support_width", value);
                }}
              />
            </InputRow>
            <InputRow label="Largura (mm)" label_col={6}>
              <Field
                name="width"
                component={InputDecimal}
                disabled={!control.dimensions_control}
                onChange={(value: number) => {
                  setFieldValue("width", value);
                }}
              />
            </InputRow>
            <InputRow label="Distan. ruptura-apoio (mm)" label_col={6}>
              <Field
                name="fraction_width"
                component={InputDecimal}
                disabled={!control.dimensions_control}
                onChange={(value: number) => {
                  setFieldValue("fraction_width", value);
                }}
              />
            </InputRow>
          </>
        )}
        <InputRow label="Peso (Kg/m³)" label_col={6}>
          <Field
            name="weight"
            component={InputDecimal}
            disabled={!control.dimensions_control}
            onChange={(value: number) => {
              setFieldValue("weight", value);
            }}
          />
        </InputRow>
        <Row className="form-group">
          <label className="col-sm-6 col-form-label">
            <h3 className="m-0">Carga</h3>
          </label>
          <Col sm={6}>
            <Field
              className="form-control-xl"
              size="xl"
              name="load"
              component={InputDecimal}
              required
              onChange={(value: number) => {
                setFieldValue("load", value);
              }}
              autoFocus={!control.dimensions_control}
            />
          </Col>
        </Row>
        <dl className="row">
          <dt className="col-sm-6">
            <h3>FCK (MPa)</h3>
          </dt>
          <dd className="col-sm-6 text-sm-center">
            <h3>
              {control.is_prismatic
                ? calculateFckf(
                    values.load || 0,
                    values.support_width || 0,
                    values.width || 0,
                    values.height || 0,
                    values.fraction_width || 0,
                  )
                : calculateFck(values.load || 0, values.diameter || 0)}
            </h3>
          </dd>
        </dl>
      </Col>
      <Col sm={6}>
        <dl className="row mb-0">
          <dt className="col-sm-7">
            <h4 className="h4-5 mt-1 mb-1">Idade</h4>
          </dt>
          <dd className="col-sm-5">
            <h4 className="h4-5 mt-1 mb-1">
              {values.age < 24
                ? `${values.age.toFixed(0)} Hora(s)`
                : `${(values.age / 24).toFixed(0)} Dia(s)`}
            </h4>
          </dd>

          <dt className="col-sm-7">
            <h3 className="h4-5 mt-1 mb-1">Data para ruptura</h3>
          </dt>

          <dd className="col-sm-5 align-self-center">
            <h3 className="h4-5 mt-1 mb-1">
              {formatSimpleDate(values.rupture_date)}
            </h3>
          </dd>
        </dl>

        <HeaderSectionTitle title="Formato da rupture" />
        <Row>
          {Object.keys(RUPTURE_FORM).map((key) => (
            <Col sm={12} className="custom-radio">
              <InputRadio
                id={key}
                value={key}
                label={`${RUPTURE_FORM[key]}`}
                name="rupture_form"
                checked={values.rupture_form === key}
              />
            </Col>
          ))}
        </Row>

        <HeaderSectionTitle title="Defeitos do CP" />
        <Row>
          {Object.keys(DEFECTS).map((key, index) => (
            <Col sm={6} key={index}>
              <InputCheckbox
                name={key}
                checked={(values as { [key: string]: any })[key]}
                label={DEFECTS[key]}
                onClick={() =>
                  setFieldValue(key, !(values as { [key: string]: any })[key])
                }
              />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );

  const renderSeriesHistory = () => {
    const groupedByAge: { [key: string]: TConcreteTestingBody[] } = {};
    series.concrete_mix.forEach((mix) => {
      mix.stress_test?.concrete_cp.forEach((cp) => {
        (groupedByAge[`${cp.age}`] = groupedByAge[`${cp.age}`] || []).push(cp);
      });
    });

    return (
      <>
        {Object.keys(groupedByAge).map((age) => (
          <>
            <HeaderTitle
              title={formatAgeLabel(parseInt(age))}
              className="mb-0 mt-3"
            />
            <Row>
              {groupedByAge[age].map((cp, index) => (
                <Col sm={6}>
                  <CPItem
                    code={cp.code || 0}
                    fck={
                      control.is_prismatic
                        ? calculateFckf(
                            cp.load || 0,
                            cp.support_width || 0,
                            cp.width || 0,
                            cp.height || 0,
                            cp.fraction_width || 0,
                          ).toString()
                        : calculateFck(
                            cp.load || 0,
                            cp.diameter || 0,
                          ).toString()
                    }
                    index={index}
                    load={cp.load || 0}
                  />
                </Col>
              ))}
            </Row>
          </>
        ))}
      </>
    );
  };

  return (
    <Row>
      <Col sm={9}>
        <HeaderTitle title="Dados do corpo de prova" />
        {renderCP()}
      </Col>
      <Col sm={3} className="border-left">
        <HeaderTitle title="Histórico da mistura" />
        {renderSeriesHistory()}
      </Col>
    </Row>
  );
};

export default Form;
