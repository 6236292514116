import moment from "moment";

const CPS_AGE = [
  0.5 * 24,
  14,
  1 * 24,
  1.5 * 24,
  2 * 24,
  2.5 * 24,
  3 * 24,
  4 * 24,
  7 * 24,
  8 * 24,
  10 * 24,
  11 * 24,
  14 * 24,
  21 * 24,
  28 * 24,
  56 * 24,
  63 * 24,
  90 * 24,
];
const calculateFck = (load: number, diameter: number) => {
  return parseFloat(
    ((load * 1000 * 9.80665) / (Math.PI * Math.pow(diameter / 2, 2))).toFixed(
      3,
    ),
  );
};

const calculateFckf = (
  load: number,
  supportWidth: number,
  width: number,
  height: number,
  fractionWidth: number,
) => {
  if (width === 0 || height === 0) return 0;

  const thirdPartSize = supportWidth / 3;

  if (fractionWidth < thirdPartSize * 0.95) {
    return (3 * load * 10000 * fractionWidth) / (width * Math.pow(height, 2));
  } else {
    return (load * 10000 * supportWidth) / (width * Math.pow(height, 2));
  }
};

const formatSeriesCode = (value: number) => {
  return value.toString().padStart(5, "0.000");
};

const formatCPCode = (value: number) => {
  return value.toString().padStart(7, "000.000");
};

const formatAgeLabel = (age: number) => {
  let label = "HORAS";
  if (age === 24) {
    label = "DIA";
  } else if (age >= 24) {
    label = "DIAS";
  }

  return `${age >= 24 ? age / 24 : age} ${label}`;
};

const formatDateLabel = (date: string) => {
  return moment(date, "YYYY-MM-DD").format("DD/MM");
};

export {
  calculateFck,
  calculateFckf,
  formatSeriesCode,
  formatCPCode,
  formatAgeLabel,
  formatDateLabel,
  CPS_AGE,
};
