/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import Select from "react-select";
import { Formik, Form, Field } from "formik";
import {
  InputCheckbox,
  InputColumn,
  InputText,
  InputSelect,
  InputDate,
} from "../../../components/Inputs";
import { ButtonOption } from "../../../components/Buttons";
import { getDistinctPlaces } from "../../../services/api/concrete";
import { checkFeature, fastToken, xlsx } from "../../../services/api/core";
import { BASE_REPORT_URL } from "src/services/api/api";
import { useUser } from "src/context/admin/user";
import {
  concreteStressXlsx,
  cpToBreakReport,
  stressReport,
  customConcreteXlsx,
  stressReportV2,
  cpToBreakReportV2,
} from "src/services/reports";
import { useParams } from "react-router";
import { trackReport } from "src/services/track";

interface Props {
  contractId?: number;
  controlId?: number;
}

const report_type = {
  RELATORIO_DETALHADO: 1,
  RELATORIO_COMPRESSAO: 2,
  GRAFICO_RESISTENCIA: 3,
  RELATORIO_DE_MISTURAS: 4,
  A_ROMPER: 5,
  RUPTURA_MISTURA: 6,
  RELATORIO_COMPRESSAO_XLS: 7,
  RELATORIO_QUEBEC_XLS: 8,
  RELATORIO_DETALHADO_V2: 9,
};

const ReportBar: React.FC<Props> = ({ contractId, controlId }) => {
  const [user] = useUser();
  const [places, setPlaces] = useState([]);
  const [optionalPlaces, setOptionalPlaces] = useState([]);
  const { appSlug } = useParams<{ appSlug: string }>();
  const [customReports, setCustomReports] = useState<Xlsx[]>([]);
  const [generating, setGenerating] = useState<boolean>(false);

  const getPlaces = () => {
    if (contractId !== undefined) {
      getDistinctPlaces(contractId).then(({ data }) => {
        setPlaces(data.places);
        setOptionalPlaces(data.optional_places);
      });
    }
  };

  useEffect(() => {
    xlsx("C", "name,id").then(({ data }) => {
      setCustomReports(data);
    });
  }, []);

  useEffect(() => {
    getPlaces();
    reportOptions();
  }, [contractId]);

  const onSubmit = (values: any) => {
    setGenerating(true);
    const args =
      `?molding_start=${values.startDate}` +
      `&molding_end=${values.endDate}` +
      `&places=${values.places.join(",")}` +
      `&optional_places=${values.optionalPlaces.join(",")}` +
      `&contract=${contractId}` +
      `&report_type=${values.type}` +
      `&attach_report=${values.stressChart}` +
      `&series=${values.series}` +
      `&user=${user.data?.id}`;

    const regex =
      /((\d+\s*-\s*\d+\s*)|(\d+\s*))+(,\s*((\d+\s*-\s*\d+\s*)|(\d+\s*)))*/g;
    const { series } = values;

    if (
      values.type &&
      values.type.type === "default" &&
      (series === "" || series.match(regex))
    ) {
      if (values.type.id === 1 && (series === "" || series.match(regex))) {
        trackReport("concrete", "series");
        fastToken().then(({ data }) => {
          if (contractId !== undefined) {
            stressReport(
              contractId,
              values.startDate,
              values.endDate,
              values.places,
              values.optionalPlaces,
              values.series,
              appSlug,
              data.access,
              values.stressChart,
            );
          }
          setGenerating(false);
        });
      } else if (
        values.type.id === report_type.RELATORIO_DETALHADO_V2 &&
        (series === "" || series.match(regex))
      ) {
        trackReport("concrete", "series_v2");
        fastToken().then(({ data }) => {
          if (contractId !== undefined) {
            stressReportV2(
              contractId,
              values.startDate,
              values.endDate,
              values.places,
              values.optionalPlaces,
              values.series,
              appSlug,
              data.access,
              values.stressChart,
            );
          }
          setGenerating(false);
        });
      } else if (values.type.id === report_type.A_ROMPER) {
        trackReport("concrete", "a_romper");
        fastToken().then(({ data }) => {
          if (controlId !== undefined) {
            cpToBreakReportV2(
              values.startDate,
              values.endDate,
              appSlug,
              data.access,
              controlId,
              true,
            );
          }
          setGenerating(false);
        });
      } else if (values.type.id === report_type.RELATORIO_COMPRESSAO_XLS) {
        if (controlId !== undefined) {
          concreteStressXlsx(
            controlId,
            values.startDate,
            values.endDate,
            values.places,
            values.optionalPlaces,
            values.series,
          )
            .then(({ data }) => {
              window.open(data.file, "_blank");
              setGenerating(false);
            })
            .catch(() => {
              internalReport(args);
            });
        }
      } else {
        internalReport(args);
      }
    } else if (values.type && values.type.type === "custom") {
      trackReport("concrete", "xlsx");
      if (controlId !== undefined) {
        customConcreteXlsx(
          values.type.id,
          controlId,
          values.startDate,
          values.endDate,
          values.places,
          values.optionalPlaces,
          values.series,
        ).then(({ data }) => {
          window.open(data.file, "_blank");
          setGenerating(false);
        });
      }
    }
  };

  const internalReport = (urlArgs: string) => {
    checkFeature("concrete_report_v2")
      .then(() => {
        const url = `${BASE_REPORT_URL}concrete/reports/report/v2${urlArgs}`;
        window.open(url, "_blank");
        setGenerating(false);
      })
      .catch(() => {
        const url = `reports/report${urlArgs}`;
        window.open(url, "_blank");
        setGenerating(false);
      });
  };

  const [options, setOptions] = useState<any>([]);
  const reportOptions = async () => {
    let goStressReport = false;
    try {
      await checkFeature("go_report_stress");
      goStressReport = true;
    } catch (e) {}
    const _options = [];
    if (!goStressReport) {
      _options.push({
        value: { type: "default", id: report_type.RELATORIO_DETALHADO },
        label: "Resistência à compressão completo",
      });
      _options.push({
        value: { type: "default", id: report_type.RELATORIO_DETALHADO_V2 },
        label: "Resistência à compressão completo (teste)",
      });
    } else {
      _options.push({
        value: { type: "default", id: report_type.RELATORIO_DETALHADO_V2 },
        label: "Resistência à compressão completo",
      });
    }
    _options.push({
      value: { type: "default", id: report_type.RELATORIO_COMPRESSAO },
      label: "Resistência à compressão",
    });
    _options.push({
      value: { type: "default", id: report_type.RELATORIO_COMPRESSAO_XLS },
      label: "Planilha de resistência a compressão",
    });

    if (!user.data?.is_client) {
      _options.push({
        value: { type: "default", id: report_type.A_ROMPER },
        label: "CPs a romper",
      });
    }

    customReports.forEach((item) => {
      _options.push({
        value: { type: "custom", id: item.id || -1 },
        label: item.name || "",
      });
    });

    setOptions(_options);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      enableReinitialize
      initialValues={{
        stress_chart: false,
        places: [],
        optionalPlaces: [],
        startDate: "",
        endDate: "",
        stressChart: true,
        series: "",
      }}
      render={({ values, setFieldValue }) => (
        <Form>
          <Card>
            <CardBody>
              <Row>
                <Col sm={3}>
                  <InputColumn label="Local">
                    <Select
                      isMulti
                      styles={{
                        container: () => ({
                          width: "100%",
                          padding: "0",
                        }),
                      }}
                      options={places.map((item) => ({
                        value: item,
                        label: item,
                      }))}
                      onChange={(values: any) =>
                        setFieldValue(
                          "places",
                          values.map((item: any) => item.value),
                        )
                      }
                    />
                  </InputColumn>
                </Col>

                <Col sm={2}>
                  <InputColumn label="Detalhe do local">
                    <Select
                      isMulti
                      styles={{
                        container: () => ({
                          width: "100%",
                          padding: "0",
                        }),
                      }}
                      options={optionalPlaces.map((item) => ({
                        value: item,
                        label: item,
                      }))}
                      onChange={(values: any) =>
                        setFieldValue(
                          "optionalPlaces",
                          values.map((item: any) => item.value),
                        )
                      }
                    />
                  </InputColumn>
                </Col>

                <Col sm={2}>
                  <InputColumn label="Período de moldagem">
                    <Field name="startDate" component={InputDate} />
                  </InputColumn>
                  <InputColumn>
                    <Field name="endDate" component={InputDate} />
                  </InputColumn>
                  <InputColumn>
                    <InputCheckbox
                      name="stressChart"
                      label="Anexar gráfico"
                      checked={values.stressChart}
                      onClick={() =>
                        setFieldValue("stressChart", !values.stressChart)
                      }
                    />
                  </InputColumn>
                </Col>

                <Col>
                  <InputColumn label="Séries">
                    <Field name="series" component={InputText} />
                    <small>Ex.: 88, 90, 100-110</small>
                  </InputColumn>
                </Col>
                <div className="col-sm-3">
                  <InputColumn label="Modelo do relatório">
                    <InputSelect
                      onClick={(value: any) => {
                        setFieldValue("type", value);
                      }}
                      options={options}
                      required
                    />
                  </InputColumn>
                  <ButtonOption
                    type="submit"
                    className="btn-block"
                    disabled={generating}
                    isSubmitting={generating}
                  >
                    Gerar relatório
                  </ButtonOption>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Form>
      )}
    />
  );
};

export default ReportBar;
